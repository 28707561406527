(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// (function () {
//     const WebFontConfig = {
//       google: {
//         families: [
//           'Abhaya Libre:400,800:latin'
//         ]
//       }
//     };
//     let wf = document.createElement("script");
//     wf.src =
//       ("https:" == document.location.protocol ? "https" : "http") +
//       "://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";
//     wf.type = "text/javascript";
//     wf.async = "true";
//     wf.rel = "preload";
//     let s = document.getElementsByTagName("script")[0];
//     s.parentNode.insertBefore(wf, s);
//   })();
document.addEventListener("DOMContentLoaded", function () {
  //(navigator.userAgent.indexOf("Mobile") <= 0)&&inactiveLinks();
  var scrolled = window.pageYOffset || document.documentElement.scrollTop;
  var jsParallax = document.querySelectorAll(".js-parallax");

  if (jsParallax) {
    window.addEventListener('scroll', function (e) {
      scrolled = window.pageYOffset || document.documentElement.scrollTop;
      jsParallax.forEach(function (el) {
        el.style.top = -(scrolled * 0.2) + 'px';
      });
    });
  }

  var jsPlaceholder = document.querySelectorAll(".js-placeholder");

  if (jsPlaceholder) {
    jsPlaceholder.forEach(function (el) {
      return setPlaceholder(el);
    });
  } // function smoothscroll() {
  //   if (scrolled > 0) {
  //     window.requestAnimationFrame(smoothscroll);
  //     window.scrollTo(0, scrolled - (scrolled / 50));
  //   }
  // };

  /*scroll top*/


  var goTop = document.querySelector(".js-go-top");
  if (goTop) goTop.addEventListener("click", function (e) {
    //smoothscroll();
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    var scrollToTop = window.setInterval(function () {
      if (scrolled > 0) {
        window.scrollTo(0, scrolled - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  });
  /*menu switcher*/

  var menuSwitcher = document.querySelector('.js-c-nav__switcher');
  var menu = document.querySelector('.js-c-nav');
  var menuParent = document.querySelector('.js-c-nav__parent');
  var body = document.querySelector('body');

  if (menuSwitcher && menu && menuParent) {
    menuSwitcher.addEventListener('click', function (e) {
      body.classList.toggle('is-c-nav--active');
      menuSwitcher.classList.toggle('is-c-nav__switcher--open');
      menu.classList.toggle('is-c-nav--open');
      menuParent.classList.toggle('is-c-nav__parent--open');
    });
  } //cookies


  (function () {
    var jsCookiesPopup = document.querySelector(".js-cookies-popup");
    var jsCookiesClose = document.querySelector(".js-cookies-close");
    var jsCookiesOk = document.querySelector(".js-cookies-ok");
    var jsCookiesNo = document.querySelector(".js-cookies-no");
    var jsCookiesPopupBg = document.querySelector(".js-cookies-popup-bg");

    function removePopup() {
      jsCookiesPopupBg.parentNode.removeChild(jsCookiesPopupBg);
      jsCookiesPopup.classList.add('c-cookies-popup--hide');
      setTimeout(jsCookiesPopup.parentNode.removeChild(jsCookiesPopup), 5000);
      console.log('remove');
    }

    function setCookies() {
      console.log('set');
    }

    function akceptPopup(e) {
      removePopup();
      setCookies();
      e.currentTarget.removeEventListener('click', akceptPopup);
    }

    if (jsCookiesPopup) {
      jsCookiesClose.addEventListener('click', akceptPopup);
      jsCookiesOk.addEventListener('click', akceptPopup);
      jsCookiesNo.addEventListener('click', removePopup);
    }
  })();

  function setCookie(name, value, days) {
    var expires = "";

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  initForm();
});

function initForm() {
  var helloForm = document.querySelector('.js-hello-form');
  helloForm && helloForm.querySelector('.js-hello-submit').addEventListener('click', function (e) {
    e.preventDefault();
    setCaptcha(helloForm);
  });
}

function setCaptcha(helloForm) {
  grecaptcha.ready(function () {
    // do request for recaptcha token
    // response is promise with passed token
    //name of action only a-z and _
    grecaptcha.execute('6Ldho1IaAAAAAGwnfekXicRgg3LuQTnhTgC6YEY_', {
      action: 'g_recaptcha_validate'
    }).then(function (result) {
      document.getElementById('js-hello-g-recaptcha-response').value = result;
      checkForm(helloForm) && sendForm(helloForm);
    }, function (error) {
      console.error(error);
    })["catch"](function (error) {
      console.error(error);
    });
  });
}

function checkForm(helloForm) {
  helloForm.querySelectorAll('.js-required').forEach(function (el) {
    el.classList.remove('is-error-value');
    helloForm.querySelector("label[for=\"".concat(el.name, "\"]")).classList.remove('is-error-value');
  });
  var validations = {
    required: function required(value) {
      return value !== '';
    },
    phone: function phone(value) {
      var testPhone = new RegExp(/^([+,0-9]{3})?[ ,\.]{0,1}[0-9-, ]{8,12}$/);
      return testPhone.test(value);
    },
    email: function email(value) {
      var testEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      return testEmail.test(value);
    }
  };
  var isFormError = false;
  var helloName = helloForm.querySelector('[name="hello-name"]'),
      helloEmail = helloForm.querySelector('[name="hello-email"]'),
      helloPhone = helloForm.querySelector('[name="hello-phone"]'),
      helloAdress = helloForm.querySelector('[name="hello-adress"]'),
      helloMessage = helloForm.querySelector('[name="hello-message"]'),
      helloCaptcha = helloForm.querySelector('#js-hello-g-recaptcha-response');

  if (!validations.required(helloName.value)) {
    isFormError = true;
    helloName.classList.add('is-error-value');
    helloForm.querySelector("label[for=\"".concat(helloName.name, "\"]")).classList.add('is-error-value');
  }

  if (!validations.phone(helloPhone.value)) {
    isFormError = true;
    helloPhone.classList.add('is-error-value');
    helloForm.querySelector("label[for=\"".concat(helloPhone.name, "\"]")).classList.add('is-error-value');
  }

  if (!validations.email(helloEmail.value)) {
    isFormError = true;
    helloEmail.classList.add('is-error-value');
    helloForm.querySelector('[name="hello-phone"]');
    helloForm.querySelector("label[for=\"".concat(helloEmail.name, "\"]")).classList.add('is-error-value');
  }

  if (!validations.required(helloAdress.value)) {
    isFormError = true;
    helloAdress.classList.add('is-error-value');
    helloForm.querySelector("label[for=\"".concat(helloAdress.name, "\"]")).classList.add('is-error-value');
  }

  if (!validations.required(helloMessage.value)) {
    console.log('hv' + helloMessage.value);
    isFormError = true;
    helloMessage.classList.add('is-error-value');
    helloForm.querySelector("label[for=\"".concat(helloMessage.name, "\"]")).classList.add('is-error-value');
  }

  if (!validations.required(helloCaptcha.value)) {
    isFormError = true;
    showAllert('Błąd re-captcha. Aktualnie nie można wysłać wiadomości przez formularz.', false);
  }

  return !isFormError;
}

function showAllert(text, isCorrect) {
  var helloFormInfo = document.querySelector('.js-hello-form-info');
  helloFormInfo.textContent = '';
  var classInfo = "c-alert__error";
  if (isCorrect) classInfo = "c-alert__correct";
  var elHelloFormInfo = document.createElement("div");
  elHelloFormInfo.classList.add(classInfo);
  helloFormInfo.appendChild(elHelloFormInfo);
  elHelloFormInfo.innerHTML = text;
}

function sendForm(helloForm) {
  showAllert('przetwarzanie', true);
  var helloFormData = new FormData(helloForm);
  helloFormData.append("action", "g_recaptcha_validate");
  axios({
    method: 'post',
    url: 'sendmail/sendmail-re.php',
    data: helloFormData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function (response) {
    var responseData = response.data;
    showAllert(responseData.info, responseData.data);

    if (responseData.errorEl) {
      for (var val in responseData.errorEl) {
        var el = helloForm.querySelector("[name=\"".concat(val, "\"]"));
        el.classList.add('is-error-value');
        helloForm.querySelector("label[for=\"".concat(el.name, "\"]")).classList.add('is-error-value');
      }
    } else helloForm.remove();
  }, function (error) {
    showAllert("Błąd wewnętrzny formularza.", false);
  });
}

var initPlaceholder = function initPlaceholder(e) {//console.log(e);
  // setPlaceholder(e.currentTarget);
};

var movePlaceholder = function movePlaceholder(e) {// console.log(e);
};

var setPlaceholder = function setPlaceholder(el) {
  var elInfo = document.createElement("div");
  elInfo.classList.add("js-holder-info");
  el.parentNode.insertBefore(elInfo, el);
  elInfo.appendChild(el);
  var elTitle = el.getAttribute("title");
  var elInfoSpan = document.createElement("span");
  elInfoSpan.classList.add("js-holder-info__span");
  elInfo.insertBefore(elInfoSpan, el);
  elInfoSpan.innerHTML = elTitle;

  if (el.value.length != 0) {
    elInfo.classList.add("is-value");
    elInfo.classList.remove("is-focussed");
  }

  el.addEventListener('focus', function () {
    elInfo.classList.add("is-focussed");
    elInfo.classList.remove("is-value");
  });
  el.addEventListener('blur', function (e) {
    if (el.value.length != 0) {
      elInfo.classList.add("is-value");
      elInfo.classList.remove("is-focussed");
    } else elInfo.classList.remove("is-focussed", "is-value");
  });
}; // function scrollToEl(el) {
//   console.log(el);
//   let scrolledEl = el.scrollTop;
//   console.log(el.scrollLeft, el.scrollTop);
//   let scrolled = window.pageYOffset || document.documentElement.scrollTop;
//   const scrollToTop = window.setInterval(function () {
//     console.log(scrolled);
//     console.log(scrolledEl);
//     if (scrolled > scrolledEl) {
//       window.scrollTo(0, scrolled - 20);
//     } else {
//       window.clearInterval(scrollToTop);
//     }
//   }, 2000);
// }

},{}]},{},[1]);
